import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { usePopupContext } from '../../contextApi/PopupContextHandler';

const Popup = () => {
	const [isOpen, setIsOpen] = useState(false);
	const {setStyleChange, setFirstTime} = usePopupContext();

	const handleClose = () => {
		setIsOpen(false);
		setStyleChange('auto');
		setFirstTime(false);
	};
	const modalStyles = {
		content: {
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			width: `${window?.innerWidth >= 1537 ? '550px' : window?.innerWidth >=600 ?  '400px': window?.innerWidth>=500 && '300px'}`,
			background: '#f1f1f1',
			textAlign: 'center',
			border: 'none',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change the background overlay color here
		},
	};



	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={handleClose}
				style={modalStyles}
				className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2   text-center"
			>
				{/* <img
					className="h-[45px] w-[150px] mx-auto mb-8"
					src="/assets/logo.png"
					alt="logo"
				/> */}
				<div className="flex flex-col text-left">
					<Link to="/senior">
						<img src="/assets/ads.png" className="h-full w-full" />
					</Link>
					{/* <a
						href="#"
						className=" font-medium text-lg p-2 text-gray hover:text-blk hover:ease-in-out duration-300 "
					>
						{' '}
						Arniko School
					</a>
					<a
						href="#"
						className=" font-medium text-lg p-2 text-gray hover:text-blk hover:ease-in-out duration-300"
					>
						Arniko College{' '}
					</a> */}
				</div>

				<button
					onClick={handleClose}
					className="text-lg absolute top-2 right-2"
				>
					<AiOutlineClose className="text-2xl text-white" />
				</button>
			</Modal>
		</>
	);
};

export default Popup;
