import React from 'react';
import Filled from './Filled';
import '../../../src/index.css';

const Bgreact = (props) => {
	// const myStyle = {
	//   width: "100%",
	//   // height: "100vh",
	//   backgroundImage: 'url("/assets/school.jpg")',
	//   backgroundSize: "cover",
	//   backgroundPosition: "center",
	// };
	return (
		<div
			className={`relative  transition duration-400 ease-in-out ${'button-div'}`}
		>
			<div className="mt-4 hover:opacity-50 relative ">
				<div>
					<img src={props.img} className="bg-cover w-full" />
					{/* <div className="div h-10 w-10 bg-blue  absolute top-1 hover: transform hover:-translate-y-6  duration-400">
						eqwe
					</div> */}
					{/* <div className=" text-center font-semibold text-dark-text ">
            {props.title}
          </div> */}
				</div>
				<p className="text-center font-semibold pt-4 text-light-text text-sm mb-8 ">
					{props.content}
				</p>
			</div>
			<div className={`${'button'}`}>
				<Filled tit="Learn More" />
			</div>
		</div>
	);
};

export default Bgreact;
