import { IoIosArrowUp } from 'react-icons/io';

export const MapSection = () => {
	return (
		<div className="bg-cover ">
			<div className="relative mt-5">
				<img
					src="/assets/map.png"
					alt="map"
					className="w-full h-full bg-cover"
				/>
				<a
					href="#top"
					className="h-[50px] w-[137px] bg-dblue absolute bottom-0 left-1/2 transform -translate-x-1/2  flex justify-center  hover:bg-arniko-red  transition ease-in-out duration-300"
				>
					<IoIosArrowUp className="text-white text-3xl text-center  mt-3 " />
				</a>
			</div>
		</div>
	);
};
