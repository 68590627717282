import { Admissions } from '../../components/modules/Landing/Admissions';
import { HeroSection } from '../../components/modules/Landing/HeroSection';
import { JoinUsSection } from '../../components/modules/Landing/JoinUsSection';
import { LifeAtArniko } from '../../components/modules/Landing/LifeAtArniko';
import { MapSection } from '../../components/modules/Landing/MapSection';
import { WhyUs } from '../../components/modules/Landing/WhyUs';
import { usePopupContext } from '../../contextApi/PopupContextHandler';
import Popup from '../auth/Popup';

export const LandingPage = () => {
	const {styleChange, firstTime} = usePopupContext();
	return (
		<main>
		{/* // <main className={`font-pop ${styleChange === 'hidden' ? "overflow-hidden no-scrollbar" : "overflow-x-hidden"} `} style={{ height: styleChange === 'hidden' ? '90vh':'auto'}}> */}
			{firstTime && <Popup />}
			<HeroSection />
			<JoinUsSection />
			<Admissions />
			<LifeAtArniko />
			<WhyUs />
			<MapSection />
		</main>
	);
};
