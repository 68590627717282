import Filled from '../../common/Filled';

export const HeroSection = () => {
	// const myStyle = {
	//   width: "100%",
	//   height: "100vh",
	//   backgroundImage: 'url("/assets/main.png")',
	//   backgroundSize: "cover",
	//   backgroundPosition: "center",
	// };
	// const videoId = 'qe1XbD23RGA';
	return (
		<div>
			<div className="flex flex-col justify-end h-screen ">
				{/* <video controls>
          <source src="/assets/video.mp4" type="video/mp4" />
        </video> */}
				<div className="video  h-full relative">
					<iframe
						className="w-full h-full absolute top-0 left-0 "
						src="https://www.youtube.com/embed/t9BXCK_L0Ho?rel=0&autoplay=1&loop=1&modestbranding=1&rel=0"
						title="Arniko College Promotional Video"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
						// style={{height:'100%', width:'100%', objectFit:'cover'}}
						// controls
						// autoPlay loop 
						// muted
					  >
						{/* <source src={ArnikoPromoVideo} type='video/mp4' /> */}
					</iframe>

					<div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
						<a href="https://forms.gle/TsBb8zEWo43kKm4f9">
							<Filled tit="ARRANGE A VISIT" />
						</a>
					</div>

					{/* <video controls width="250">
						<source src="/media/cc0-videos/flower.webm" type="video/webm" />
					</video> */}
				</div>

				{/* <div className="bottom__nav p-4">
          <div className="lg:flex lg:justify-between">
            <BsFillVolumeMuteFill className="h-10 w-10 text-white" />
            <div className="wrapper lg:flex lg:gap-2 md:flex md:gap-2">
              <div className="flex flex-col justify-center p-1">
                <Filled tit="ARRANGE A VISIT" />
              </div>
              <div className="flex flex-col justify-center p-1">
                <Filled tit="REQUEST INFO" />
              </div>
            </div>
            <div className="wrapper invisible flex gap-1 lg:visible">
              <a href="#bottom">
                <BsArrowDownCircle className="h-10 w-10 text-white  " />
              </a>
              <AiOutlinePauseCircle className="h-10 w-10 text-white " />
            </div>
          </div>
        </div> */}
			</div>
		</div>
	);
};
